<script lang="ts">
    import { onMount } from 'svelte';

    export let searchTerm: string = '';
    export let activeFilter: string = 'top';
    export let searchFilters: {
        gender?: string;
        min_age?: string;
        max_age?: string;
        location?: string;
    } = {};

    let searchResults: any[] = [];
    let recentSearches: { text: string; link: string }[] = [];
    let showSuggestions: boolean = false;
    let searchInputElement: HTMLInputElement;

    $: hasContent =
        (recentSearches.length > 0 && searchTerm.trim() === '') ||
        (searchResults.length > 0 && searchTerm.trim() !== '');

    onMount(() => {
        recentSearches = getRecentSearches();
    });

    function getRecentSearches(): { text: string; link: string }[] {
        const searches = JSON.parse(localStorage.getItem('recentSearches') || '[]');
        return searches.filter((search: any) => search && search.text && search.text !== 'undefined');
    }

    function addRecentSearch(searchObj: { text: string; link: string }): void {
        if (!searchObj.text || !searchObj.link) {
            console.error('Invalid search object:', searchObj);
            return;
        }
        let searches = getRecentSearches();
        searches = searches.filter((search) => search.text !== searchObj.text);
        searches.unshift(searchObj);
        searches = searches.slice(0, 3);
        localStorage.setItem('recentSearches', JSON.stringify(searches));
        recentSearches = searches;
    }

    function handleFocus(): void {
        recentSearches = getRecentSearches();
        showSuggestions = recentSearches.length > 0;
    }

    function handleInput(): void {
        if (searchTerm.trim() === '') {
            searchResults = [];
            showSuggestions = recentSearches.length > 0;
            return;
        }

        clearTimeout(searchTimeout);
        searchTimeout = setTimeout(fetchSearchResults, 300);
    }

    let searchTimeout: ReturnType<typeof setTimeout>;

    async function fetchSearchResults(): Promise<void> {
        try {
            const response = await fetch(`/search/v1/users?query=${searchTerm}`);
            const data = await response.json();
            searchResults = data.data.data;
            showSuggestions = true;
        } catch (error) {
            console.error('Error fetching search results:', error);
            searchResults = [];
        }
    }

    function handleSubmit(): void {
        const baseUrl = window.location.origin;
        const searchUrl = new URL(`${baseUrl}/search`);

        searchUrl.searchParams.append('query', searchTerm);
        searchUrl.searchParams.append('filter', activeFilter);

        Object.entries(searchFilters).forEach(([key, value]) => {
            if (value) {
                searchUrl.searchParams.append(key, value);
            }
        });

        window.location.href = searchUrl.toString();
    }

    function clearAllRecentSearches(): void {
        localStorage.removeItem('recentSearches');
        recentSearches = [];
        showSuggestions = false;
    }

    function deleteRecentSearch(searchText: string): void {
        recentSearches = recentSearches.filter((search) => search.text !== searchText);
        localStorage.setItem('recentSearches', JSON.stringify(recentSearches));
        if (recentSearches.length === 0) {
            showSuggestions = false;
        }
    }

    function handleSuggestionClick(event: MouseEvent, searchText: string, searchLink: string): void {
        event.preventDefault();
        if (searchText && searchText !== 'undefined' && searchLink) {
            addRecentSearch({ text: searchText, link: searchLink });
            window.location.href = searchLink;
        }
    }

    function handleClickOutside(event: MouseEvent): void {
        if (searchInputElement && !searchInputElement.contains(event.target as Node)) {
            showSuggestions = false;
        }
    }
</script>

<svelte:window on:click="{handleClickOutside}" />

<form on:submit|preventDefault="{handleSubmit}" class="relative w-full">
    <div class="relative flex items-center">
        <input
            type="text"
            class="w-full rounded-lg border border-gray-300 py-2 pl-4 pr-10 focus:border-bmn-500 focus:outline-none focus:ring-2 focus:ring-bmn-500 dark:!border-neutral-800 dark:!bg-black dark:text-white"
            required
            aria-label="Search input"
            placeholder="Search"
            bind:value="{searchTerm}"
            on:input="{handleInput}"
            on:focus="{handleFocus}"
            bind:this="{searchInputElement}"
            autocomplete="off"
        />
        <button
            type="submit"
            class="absolute right-2 rounded-full bg-bmn-50 p-2 text-bmn-500 transition-all duration-300 hover:bg-bmn-100 dark:bg-bmn-400 dark:bg-opacity-10 dark:text-bmn-500 dark:hover:bg-opacity-20"
        >
            <svg
                class="h-4 w-4"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="3"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                ></path>
            </svg>
        </button>
    </div>
    {#if showSuggestions && hasContent}
        <div
            class="absolute z-10 mt-1 max-h-60 w-full overflow-y-auto rounded-lg border bg-white shadow-lg dark:!border-neutral-800 dark:!bg-black"
        >
            {#if recentSearches.length > 0 && searchTerm.trim() === ''}
                <div
                    class="flex justify-between border-b border-gray-200 px-4 py-2 text-sm font-normal dark:!border-neutral-800"
                >
                    <span>Recent</span>
                    <button class="text-bmn-500 hover:underline" on:click="{clearAllRecentSearches}">Clear All</button>
                </div>
                {#each recentSearches as search}
                    <div
                        class="flex items-center justify-between px-4 py-3 hover:bg-bmn-50 dark:hover:bg-bmn-400 dark:hover:bg-opacity-20"
                    >
                        <a
                            class="flex-grow text-base font-normal text-black no-underline hover:text-black dark:text-white dark:hover:text-white"
                            href="{search.link}"
                            on:click|preventDefault="{(e) => handleSuggestionClick(e, search.text, search.link)}"
                        >
                            {search.text}
                        </a>
                        <button
                            class="ml-2 text-gray-500 hover:text-bmn-500"
                            on:click="{() => deleteRecentSearch(search.text)}"
                        >
                            &times;
                        </button>
                    </div>
                {/each}
            {:else if searchResults.length > 0}
                {#each searchResults as result}
                    <a
                        href="/{result.username}"
                        class="flex items-center px-4 py-2 hover:bg-bmn-50 dark:hover:bg-bmn-400 dark:hover:bg-opacity-20"
                        on:click|preventDefault="{(e) => handleSuggestionClick(e, result.name, `/${result.username}`)}"
                    >
                        <img src="{result.avatar}" alt="avatar" class="mr-3 h-10 w-10 rounded-full object-cover" />
                        <div class="flex flex-col">
                            <span class="font-bold text-black dark:text-white">{result.name}</span>
                            <span class="text-sm text-gray-600 dark:text-gray-400">@{result.username}</span>
                        </div>
                    </a>
                {/each}
            {/if}
        </div>
    {/if}
    <input type="hidden" name="filter" value="{activeFilter}" />
    {#each Object.entries(searchFilters) as [key, value]}
        {#if value}
            <input type="hidden" name="{key}" {value} />
        {/if}
    {/each}
</form>
